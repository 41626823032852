import { useCallback, useMemo, useState } from 'react'
import React from 'react';
import { mutate } from 'swr';
import { Cog6ToothIcon, CreditCardIcon } from '@heroicons/react/24/outline';
import Head from 'next/head'
import AsideNavigation from '../../components/dashboard/AsideNavigation'
import useCurrentAccount from '../../components/context/useCurrentAccount';
import usePaymentMethods from '../../components/context/usePaymentMethods';
import CenteredModalDialog from '../../components/blocks/CenteredModalDialog';

export type Subscription = {
    id: string,
    name: string,
    price: number,
    monitors: number,
    daily_emails: number,
}

export const allSubscriptions: Subscription[] = [
    { id: 'free', name: 'Free', price: 0, monitors: 10, daily_emails: 10 },
    { id: '10', name: 'Pro 10', price: 495, monitors: 10, daily_emails: 10 },
    { id: '50', name: 'Pro 50', price: 1495, monitors: 50, daily_emails: 50 },
    { id: '100', name: 'Pro 100', price: 2495, monitors: 100, daily_emails: 100 },
    { id: '200', name: 'Pro 200', price: 4995, monitors: 200, daily_emails: 200 },
    { id: '500', name: 'Pro 500', price: 9995, monitors: 500, daily_emails: 500 },
    { id: '1000', name: 'Pro 1000', price: 19995, monitors: 1000, daily_emails: 1000 },
]

export function subscriptionPlan(planId: string) {
    if (planId == 'free') {
        return allSubscriptions[0]
    } else {
        const planMonitors = planId.split('-')[0]
        const subscription = allSubscriptions.find(s => s.id == planMonitors)

        return subscription || allSubscriptions[0]
    }
}

function planName(planId: string) {
    if (planId == 'free') {
        return 'Free'
    } else {
        return `Pro (${planId.split('-')[0]} monitors)`
    }
}

const emailPackages = [
    { email: 1000, price: 495 },
    { email: 3000, price: 995 },
    { email: 10000, price: 1995 },
]

const smsPackages = [
    { sms: 100, price: 1495 },
    { sms: 200, price: 2495 },
    { sms: 500, price: 5495 },
    { sms: 1000, price: 9995 },
]

export default function DashboardSettingsPage() {
    const { account } = useCurrentAccount({ requireLoggedIn: true })
    const { paymentMethods, isLoading: paymentMethodsLoading, isError: paymentMethodsError } = usePaymentMethods()
    const [changePlanFormOpen, setChangePlanFormOpen] = useState(false)
    const [changePlanProcessing, setChangePlanProcessing] = useState(false)
    const [paymentMethodDeleting, setPaymentMethodDeleting] = useState(false)
    const [makingDefault, setMakingDefault] = useState(false)

    const [prepaidEmailFormOpen, setPrepaidEmailFormOpen] = useState(false)
    const [prepaidEmailProcessing, setPrepaidEmailProcessing] = useState(false)

    const [prepaidSmsFormOpen, setPrepaidSmsFormOpen] = useState(false)
    const [prepaidSmsProcessing, setPrepaidSmsProcessing] = useState(false)

    const currentSubscription = useMemo(
        () => subscriptionPlan(account?.current_plan || ''),
        [account?.current_plan]
    )

    const changePlanBuyButtonClicked = useCallback((planName: string, subscriptionTerm?: 'monthly' | 'annually') => {
        if (changePlanProcessing) return;

        setChangePlanProcessing(true)

        if (paymentMethods.length == 0 && planName != 'free') {
            alert('You need to add a payment method first.');
            setChangePlanProcessing(false);
            return
        }

        let planId = planName == 'free' ? 'free' : `${planName}-${subscriptionTerm}`

        fetch(`/web_api/billing/change_subscription/${planId}`,
            {
                method: 'POST',
            })
            .then(resp => {
                if (resp.status == 200) {
                    resp.json().then(json => {
                        if (json.status == 'subscription_changed') {
                            mutate('/web_api/accounts/current');
                            setChangePlanFormOpen(false);
                        } else if (json.status == 'already_on_this_plan') {
                            alert('You are already on this plan.')
                            setChangePlanFormOpen(false);
                        } else {
                            alert(json.error || json.reason || 'Error. Try again later.');
                        }

                        setChangePlanProcessing(false)
                    }).catch(e => {
                        alert(e.toString() || 'Error. Try again later.');
                        setChangePlanProcessing(false)
                    })
                } else {
                    alert('Error. Try again later.')
                    setChangePlanProcessing(false)
                }
            })

    }, [paymentMethods.length, changePlanProcessing])

    const addPaymentMethodClicked = useCallback(() => {
        if (changePlanProcessing) return;
        setChangePlanProcessing(true)

        fetch(`/web_api/billing/payment_methods`,
            {
                method: 'POST',
            })
            .then(resp => {
                if (resp.status == 200) {
                    resp.json().then(json => {
                        if (json.status == 'ok') {
                            if (json.url) {
                                window.location = json.url;
                            }
                        } else {
                            alert(json.error || 'Error. Try again later.');
                        }

                        setChangePlanProcessing(false)
                    }).catch(e => {
                        alert(e.toString() || 'Error. Try again later.');
                        setChangePlanProcessing(false)
                    })
                } else {
                    alert('Error. Try again later.')
                    setChangePlanProcessing(false)
                }
            })
    }, [changePlanProcessing])

    const deletePaymentMethodClicked = useCallback((paymentMethodId: string) => {
        if (paymentMethodDeleting) return;

        setPaymentMethodDeleting(true)

        fetch(`/web_api/billing/payment_methods/${paymentMethodId}`, { method: 'DELETE', }).then(resp => {
            if (resp.status == 200) {
                mutate('/web_api/billing/payment_methods');
                mutate('/web_api/accounts/current');
                setPaymentMethodDeleting(false)
            } else {
                resp.json().then(json => {
                    if (json.error) {
                        alert('Error: ' + json.error)
                    } else {
                        alert('Error. Try again later.')
                    }
                    setPaymentMethodDeleting(false)
                }).catch((_e) => {
                    alert('Error. Try again later.')
                    setPaymentMethodDeleting(false)
                })
            }
        });

    }, [paymentMethodDeleting])

    const makeDefaultPaymentMethodClicked = useCallback((paymentMethodId: string) => {
        if (makingDefault) return;
        setMakingDefault(true)

        fetch(`/web_api/billing/payment_methods/${paymentMethodId}/make_default`,
            {
                method: 'POST',
            })
            .then(resp => {
                if (resp.status == 200) {
                    mutate('/web_api/accounts/current');
                    mutate('/web_api/billing/payment_methods');
                } else {
                    alert('Error. Try again later.')
                }
            }).finally(() => {
                setMakingDefault(false)
            })

    }, [makingDefault])

    const prepaidEmailBuyButtonClicked = useCallback((emailCount: number) => {
        if (prepaidEmailProcessing) return;

        setPrepaidEmailProcessing(true)

        fetch(`/web_api/billing/create_checkout_session/email_${emailCount}`,
            {
                method: 'POST',
            })
            .then(resp => resp.json())
            .then(json => {
                if (json.status == 'ok') {
                    if (json.url) {
                        window.location = json.url;
                    }
                } else {
                    // setError(json.error || 'Error. Try again later.');
                }
            }).catch(e => {
                // setError(e.toString() || 'Error. Try again later.');
            }).finally(() => {
                setPrepaidEmailProcessing(false)
            })
    }, [prepaidEmailProcessing])

    const prepaidSmsBuyButtonClicked = useCallback((smsCount: number) => {
        if (prepaidSmsProcessing) return;

        setPrepaidSmsProcessing(true)

        fetch(`/web_api/billing/create_checkout_session/sms_${smsCount}`,
            {
                method: 'POST',
            })
            .then(resp => resp.json())
            .then(json => {
                if (json.status == 'ok') {
                    if (json.url) {
                        window.location = json.url;
                    }
                } else {
                    // setError(json.error || 'Error. Try again later.');
                }
            }).catch(e => {
                // setError(e.toString() || 'Error. Try again later.');
            }).finally(() => {
                setPrepaidSmsProcessing(false)
            })
    }, [prepaidSmsProcessing])

    return (
        <div className="bg-slate-800 min-h-screen">
            <AsideNavigation activePath='/dashboard/billing'>
                <Head>
                    <title>Billing | Uptime Monitor</title>
                    {/* <meta name="description" content="Generated by create next app" /> */}
                </Head>

                <div className="px-4 sm:px-6 lg:px-8 pt-10">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-100">Billing</h1>
                            <p className="mt-2 text-sm text-gray-300">
                                Buy sms or email packages, change subscription plan.
                            </p>
                        </div>
                        {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Add user
                        </button>
                    </div> */}
                    </div>
                    <div className="-mx-4 mt-8 sm:-mx-0">
                        <table className="min-w-full divide-y divide-gray-600 max-w-xl">
                            <tbody className="divide-y divide-gray-600">
                                <tr>
                                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:w-auto sm:max-w-none sm:pl-0">
                                        Current plan
                                        <div className="font-normal lg:hidden">
                                            <div className="mt-1 truncate text-gray-100">{planName(account?.current_plan || '')}</div>
                                            <div className="mt-1 truncate text-gray-300">Daily free emails limit: {currentSubscription.daily_emails}.</div>
                                            <div className="mt-1 truncate text-gray-300">
                                                <button
                                                    onClick={() => setChangePlanFormOpen(true)}
                                                    type="button"
                                                    disabled={changePlanProcessing}
                                                    className="inline-flex items-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                                >
                                                    Change plan
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="hidden px-3 py-4 text-sm text-gray-300 lg:table-cell">
                                        <div className='flex gap-4 items-center'>
                                            <div>
                                                <span className='text-gray-100'>{planName(account?.current_plan || '')} ({currentSubscription.monitors} monitors)</span>
                                                <br />
                                                <span>Daily free emails limit: {currentSubscription.daily_emails}.</span>
                                            </div>
                                            <button
                                                onClick={() => setChangePlanFormOpen(true)}
                                                type="button"
                                                disabled={changePlanProcessing}
                                                className="inline-flex items-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                            >
                                                Change plan
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:w-auto sm:max-w-none sm:pl-0">
                                        Payment methods
                                        <div className="font-normal lg:hidden mt-1">
                                            {
                                                !paymentMethodsLoading && paymentMethods.length == 0 && (
                                                    paymentMethodsError
                                                        ?
                                                        <div>
                                                            Cannot load payment methods.
                                                        </div>
                                                        :
                                                        <div>
                                                            No payment methods added yet.
                                                        </div>
                                                )
                                            }

                                            {
                                                !paymentMethodsLoading && paymentMethods.map((paymentMethod) => (
                                                    <div key={paymentMethod._id} className=''>
                                                        <div className='inline-block rounded px-3 py-2 bg-slate-900 shadow'>
                                                            <CreditCardIcon className='inline-block w-4 h-4 mr-1' />
                                                            {paymentMethod.card_brand} ending in {paymentMethod.last4} exp {paymentMethod.exp_month}/{paymentMethod.exp_year}
                                                        </div>
                                                        {
                                                            account?.current_payment_method == paymentMethod._id
                                                                ?
                                                                <>
                                                                    <span className='ml-2 mt-3 inline-flex items-center rounded-md border-2 border-slate-700 px-2 py-1 text-sm font-medium text-white shadow-sm'>Default</span>

                                                                    {account?.current_plan == 'free' &&
                                                                        <button
                                                                            className='ml-2 mt-3 inline-flex items-center rounded-md border border-transparent bg-red-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-slate-300'
                                                                            onClick={() => deletePaymentMethodClicked(paymentMethod._id)}
                                                                            disabled={paymentMethodDeleting}
                                                                        >
                                                                            Delete
                                                                            {paymentMethodDeleting && <Cog6ToothIcon className='ml-2 animate-spin w-4 h-4' />}
                                                                        </button>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <button
                                                                        className='ml-2 mt-3 inline-flex items-center rounded-md border border-transparent bg-slate-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300'
                                                                        onClick={() => makeDefaultPaymentMethodClicked(paymentMethod._id)}
                                                                        disabled={makingDefault}
                                                                    >
                                                                        Make Default
                                                                        {makingDefault && <Cog6ToothIcon className='ml-2 animate-spin w-4 h-4' />}
                                                                    </button>
                                                                    <button
                                                                        className='ml-2 mt-3 inline-flex items-center rounded-md border border-transparent bg-red-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-slate-300'
                                                                        onClick={() => deletePaymentMethodClicked(paymentMethod._id)}
                                                                        disabled={paymentMethodDeleting}
                                                                    >
                                                                        Delete
                                                                        {paymentMethodDeleting && <Cog6ToothIcon className='ml-2 animate-spin w-4 h-4' />}
                                                                    </button>
                                                                </>
                                                        }
                                                        {/* small delete button */}

                                                    </div>
                                                ))
                                            }

                                            <button
                                                onClick={addPaymentMethodClicked}
                                                type="button"
                                                disabled={changePlanProcessing}
                                                className="mt-4 inline-flex items-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                            >
                                                Add payment method
                                            </button>
                                        </div>
                                    </td>
                                    <td className="hidden px-3 text-sm text-gray-300 lg:table-cell">
                                        {
                                            !paymentMethodsLoading && paymentMethods.length == 0 && (
                                                paymentMethodsError
                                                    ?
                                                    <div>
                                                        Cannot load payment methods.
                                                    </div>
                                                    :
                                                    <div className='mt-4'>
                                                        No payment methods added yet.
                                                    </div>
                                            )
                                        }

                                        {
                                            !paymentMethodsLoading && paymentMethods.map((paymentMethod) => (
                                                <div key={paymentMethod._id} className=''>
                                                    <div className='inline-block rounded px-3 py-2 bg-slate-900 shadow'>
                                                        <CreditCardIcon className='inline-block w-4 h-4 mr-1' />
                                                        {paymentMethod.card_brand} ending in {paymentMethod.last4} exp {paymentMethod.exp_month}/{paymentMethod.exp_year}
                                                    </div>
                                                    {
                                                        account?.current_payment_method == paymentMethod._id
                                                            ?
                                                            <>
                                                                <span className='ml-2 mt-3 inline-flex items-center rounded-md border-2 border-slate-700 px-2 py-1 text-sm font-medium text-white shadow-sm'>Default</span>

                                                                {account?.current_plan == 'free' &&
                                                                    <button
                                                                        className='ml-2 mt-3 inline-flex items-center rounded-md border border-transparent bg-red-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-slate-300'
                                                                        onClick={() => deletePaymentMethodClicked(paymentMethod._id)}
                                                                        disabled={paymentMethodDeleting}
                                                                    >
                                                                        Delete
                                                                        {paymentMethodDeleting && <Cog6ToothIcon className='ml-2 animate-spin w-4 h-4' />}
                                                                    </button>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <button
                                                                    className='ml-2 mt-3 inline-flex items-center rounded-md border border-transparent bg-slate-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300'
                                                                    onClick={() => makeDefaultPaymentMethodClicked(paymentMethod._id)}
                                                                    disabled={makingDefault}
                                                                >
                                                                    Make Default
                                                                    {makingDefault && <Cog6ToothIcon className='ml-2 animate-spin w-4 h-4' />}
                                                                </button>
                                                                <button
                                                                    className='ml-2 mt-3 inline-flex items-center rounded-md border border-transparent bg-red-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-slate-300'
                                                                    onClick={() => deletePaymentMethodClicked(paymentMethod._id)}
                                                                    disabled={paymentMethodDeleting}
                                                                >
                                                                    Delete
                                                                    {paymentMethodDeleting && <Cog6ToothIcon className='ml-2 animate-spin w-4 h-4' />}
                                                                </button>
                                                            </>
                                                    }
                                                    {/* small delete button */}

                                                </div>
                                            ))
                                        }

                                        <button
                                            onClick={addPaymentMethodClicked}
                                            type="button"
                                            disabled={changePlanProcessing}
                                            className="my-4 inline-flex items-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                        >
                                            Add payment method
                                        </button>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:w-auto sm:max-w-none sm:pl-0">
                                        Prepaid emails left
                                        <div className="font-normal lg:hidden">
                                            <div className="mt-1 truncate text-gray-100">{account?.prepaid_email_messages} email messages</div>
                                            <div className="mt-1 truncate text-gray-300">
                                                <button
                                                    onClick={() => setPrepaidEmailFormOpen(true)}
                                                    type="button"
                                                    className="inline-flex items-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                                >
                                                    Buy email package
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="hidden px-3 py-4 text-sm text-gray-300 lg:table-cell">
                                        <div className='flex gap-4 items-center'>
                                            <div>
                                                {account?.prepaid_email_messages} email messages
                                            </div>
                                            <button
                                                onClick={() => setPrepaidEmailFormOpen(true)}
                                                type="button"
                                                className="inline-flex items-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                            >
                                                Buy email package
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:w-auto sm:max-w-none sm:pl-0">
                                        Prepaid SMS left
                                        <div className="font-normal lg:hidden">
                                            <div className="mt-1 truncate text-gray-100">{account?.prepaid_sms_messages} SMS messages</div>
                                            <div className="mt-1 truncate text-gray-300">
                                                <button
                                                    onClick={() => setPrepaidSmsFormOpen(true)}
                                                    type="button"
                                                    className="inline-flex items-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                                >
                                                    Buy SMS package
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="hidden px-3 py-4 text-sm text-gray-300 lg:table-cell">
                                        <div className='flex gap-4 items-center'>
                                            <div>
                                                {account?.prepaid_sms_messages} sms messages
                                            </div>
                                            <button
                                                onClick={() => setPrepaidSmsFormOpen(true)}
                                                type="button"
                                                className="inline-flex items-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                            >
                                                Buy SMS package
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                <CenteredModalDialog show={changePlanFormOpen} onClose={() => setChangePlanFormOpen(false)} >
                    <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-800 shadow-xl rounded-lg min-w-2xl">
                        <div className="px-4 pt-6 sm:px-6">
                            Change subscription plan
                        </div>

                        <div className="px-4 py-6 sm:px-6 grid grid-col-3 items-center gap-4">
                            <div className='text-lg col-span-3'>
                                Uptime Monitor Pro
                            </div>
                            {allSubscriptions.filter(s => s.id != 'free').map((subscription) =>
                                <React.Fragment key={subscription.id}>
                                    <span>{subscription.monitors} monitors:&nbsp;</span>
                                    <button
                                        onClick={() => changePlanBuyButtonClicked(subscription.id, 'monthly')}
                                        type="button"
                                        disabled={changePlanProcessing}
                                        className="flex items-center justify-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                    >
                                        ${subscription.price / 100} monthly
                                    </button>

                                    <button
                                        onClick={() => changePlanBuyButtonClicked(subscription.id, 'annually')}
                                        type="button"
                                        disabled={changePlanProcessing}
                                        className="flex items-center justify-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                    >
                                        ${subscription.price / 10}0 annually
                                    </button>
                                </React.Fragment>
                            )}

                            <span>Free plan (10 monitors):&nbsp;</span>
                            <button
                                onClick={() => changePlanBuyButtonClicked('free')}
                                type="button"
                                disabled={changePlanProcessing}
                                className="col-span-2 flex items-center justify-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                            >
                                Switch back to free plan
                            </button>
                        </div>
                    </div>
                </CenteredModalDialog>

                <CenteredModalDialog show={prepaidEmailFormOpen} onClose={() => setPrepaidEmailFormOpen(false)} >
                    <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-800 shadow-xl rounded-lg min-w-2xl">
                        <div className="px-4 pt-6 sm:px-6">
                            Choose email package
                        </div>

                        <div className="px-4 py-6 sm:px-6 flex flex-col gap-4">
                            {emailPackages.map((emailPackage) =>
                                <button
                                    key={`email-${emailPackage.email}`}
                                    onClick={() => prepaidEmailBuyButtonClicked(emailPackage.email)}
                                    type="button"
                                    className="flex items-center justify-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                >
                                    {emailPackage.email} email messages for ${emailPackage.price / 100}
                                </button>)
                            }

                        </div>
                    </div>
                </CenteredModalDialog>

                <CenteredModalDialog show={prepaidSmsFormOpen} onClose={() => setPrepaidSmsFormOpen(false)} >
                    <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-800 shadow-xl rounded-lg min-w-2xl">
                        <div className="px-4 pt-6 sm:px-6">
                            Buy SMS package
                        </div>

                        <div className="px-4 py-6 sm:px-6 flex flex-col gap-4">
                            {smsPackages.map((smsPackage) =>
                                <button
                                    key={`sms-${smsPackage.sms}`}
                                    onClick={() => prepaidSmsBuyButtonClicked(smsPackage.sms)}
                                    type="button"
                                    className="flex items-center justify-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-slate-300"
                                >
                                    {smsPackage.sms} SMS messages for ${smsPackage.price / 100}
                                </button>)
                            }

                        </div>
                    </div>
                </CenteredModalDialog>

            </AsideNavigation>
        </div>
    )
}
