import useSWR from 'swr'
import { JSON_FETCHER } from '../fetcher';

type PaymentMethod = {
    _id: string,
    last4: string;
    card_brand: string;
    exp_month: number;
    exp_year: number;
};

type SystemSettings = {
    free_emails_daily: number;
}

export default function usePaymentMethods(params: { requireLoggedIn?: boolean } = {}): {
    paymentMethods: PaymentMethod[],
    isLoading: boolean,
    isError: any,
} {
    const { data, error } = useSWR('/web_api/billing/payment_methods', JSON_FETCHER)

    return {
        paymentMethods: data?.payment_methods || [],
        isLoading: !(data || error),
        isError: error
    }
}
