import useSWR from 'swr'
import { use, useEffect } from 'react';
import Router from 'next/router';
import { JSON_FETCHER } from '../fetcher';
import { Subscription, subscriptionPlan } from '../../pages/dashboard/billing';

type AccountInfo = {
    id: string,
    // name: string;
    email: string;
    status: string;
    down_count: number;
    prepaid_sms_messages: number;
    prepaid_email_messages: number;
    created_at: number;
    current_payment_method: string | null;
    current_plan: 'free' | '10' | '50' | '100' | '200' | '500' | '1000';

    current_subscription: Subscription,
};

export default function useCurrentAccount(params: { requireLoggedIn?: boolean } = {}): {
    account: AccountInfo,
    isLoading: boolean,
    isError: any,
} {
    let { data, error } = useSWR('/web_api/accounts/current', JSON_FETCHER)

    const { requireLoggedIn } = params || {}

    useEffect(() => {
        // if no redirect needed, just return (example: already on /dashboard)
        // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
        if (requireLoggedIn && data && !data.account) {
            Router.push('/accounts/login')
        }

        if (data?.account) {
            data.account.current_subscription = subscriptionPlan(data.account.current_plan || 'free')
        }
    }, [data, requireLoggedIn]);

    return {
        account: data?.account,
        isLoading: !(data || error),
        isError: error
    }
}
